import getEnvValue from '@joybird/joystagram-components/dist/esm/getEnvValue';

const isUjetChatEnabled = () => {
  const UJET_ENABLED =
    process.env.NEXT_PUBLIC_UJET_ENABLED ??
    getEnvValue('REACT_APP_UJET_ENABLED');

  return UJET_ENABLED === 'yes';
};

export default isUjetChatEnabled;
