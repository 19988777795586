const requestIdleCallback =
  typeof window !== 'undefined'
    ? window.requestIdleCallback
    : fn => setTimeout(fn, 0);

const loadScript = ({ id, attributes = {}, innerHTML }) => {
  const currentElement = document.getElementById(id);

  if (!currentElement) {
    const scriptElement = document.createElement('script');

    Object.entries(attributes).forEach(([key, value]) => {
      scriptElement.setAttribute(key, value);
    });

    scriptElement.setAttribute('id', id);

    if (innerHTML) {
      scriptElement.innerHTML = innerHTML;
    }

    requestIdleCallback(() => {
      document.body.appendChild(scriptElement);
    });
  }
};

export default loadScript;
