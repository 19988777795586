import loadScript from './loadScript';

const loadTalkativeScript = () => {
  setTimeout(() => {
    loadScript({
      id: 'talkative-chat-widget-script',
      innerHTML:
        "import(`https://us.engage.app/api/ecs/v1/loader/44ded7ac-55d3-4696-82ce-a869c9dce32d.js?path=${encodeURIComponent(window.location.origin+window.location.pathname)}&selectedVersion=${(new URLSearchParams(window.location.search)).get('ecsSelectedVersion') || ''}`)",
    });
  }, 1000);
};

export default loadTalkativeScript;
