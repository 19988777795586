import getHttpContextValue from '@joybird/joystagram-components/dist/esm/getHttpContextValue';

export const getFinalParamsForDynamicVariants = ({
  srcPath,
  serverRouteParams,
}) => {
  let finalParams = {};
  const urlSections = srcPath.split('/');
  const serverRouteSections = serverRouteParams['0']
    .split('/')
    .filter(v => !!v);

  const dynamicSectionIndices = urlSections
    .map((urlSection, index) => {
      if (urlSection.startsWith(':')) {
        return index;
      }
      return null;
    })
    .filter(v => ![null, undefined].includes(v));
  if (dynamicSectionIndices.length) {
    dynamicSectionIndices.forEach(dynIndex => {
      finalParams = {
        ...finalParams,
        [urlSections[dynIndex].replace(':', '')]: (
          serverRouteSections[dynIndex] || ''
        ).trim(),
      };
    });
  }

  return finalParams;
};

export const logInfo = (...args) =>
  console.log(new Date(), getHttpContextValue('reqId'), ...args);

export const logError = (...args) =>
  console.error(new Date(), getHttpContextValue('reqId'), ...args);

export const fetchPolyfills = (app, polyfills, retryCount = 0) => {
  if (!polyfills.length) {
    return app();
  }

  Promise.all(polyfills)
    .then(app)
    .catch(err => {
      logError(`Failed fetching polyfills, retry: ${retryCount}`, err);
      if (retryCount < 2) {
        fetchPolyfills(app, polyfills, retryCount + 1);
      } else {
        app();
      }
    });
};
