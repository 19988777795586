import avo from '@joybird/joystagram-components/dist/esm/avoAnalytics';
import urlAffiliateFields from '@joybird/joystagram-components/dist/esm/UrlAffiliateFields';
import getFriendlyPageType from './getFriendlyPageType';

const logPageViewEvent = pageType => {
  avo.logPageView({
    customDestinationPageName_: window.document.title,
    pageType: getFriendlyPageType(pageType),
    ...urlAffiliateFields(),
  });
};

export default logPageViewEvent;
