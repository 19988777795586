const getFriendlyPageType = pageType => {
  const logPageType = {
    home: 'Home',
    category: 'Category',
    product: 'PDP',
    cart: 'Cart',
    customerPhotos: 'Customer Photos',
  };

  return logPageType[pageType] || 'Other';
};

export default getFriendlyPageType;
