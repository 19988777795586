import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';
import { QueryParamProvider } from 'use-query-params';
import { Provider as JotaiProvider } from 'jotai';

import apolloClient from '../services/apolloClient';
import JBErrorContentProvider from '../components/JBErrorContentProvider';
import ScrollToTop from '../scrollToTop';
import history from '../history';

const AllProviders = ({ children }) => (
  <JotaiProvider>
    <ApolloProvider client={apolloClient}>
      <HelmetProvider>
        <Router history={history}>
          <QueryParamProvider ReactRouterRoute={Route}>
            <CookiesProvider>
              <ScrollToTop>
                <JBErrorContentProvider>{children}</JBErrorContentProvider>
              </ScrollToTop>
            </CookiesProvider>
          </QueryParamProvider>
        </Router>
      </HelmetProvider>
    </ApolloProvider>
  </JotaiProvider>
);

export default AllProviders;
