import React from 'react';

import App from './App';
import AllProviders from './AllProviders';
import * as Sentry from '@sentry/react';

const AppWithProviders = ({ initData }) => (
  <AllProviders>
    <App initData={initData} />
  </AllProviders>
);

export default Sentry.withProfiler(AppWithProviders);
