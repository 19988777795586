import React, { useEffect, useMemo, useState } from 'react';
import { ROUTE_ERROR_DATA_UPDATED } from '@joybird/joystagram-components/dist/esm/Constants';
import { JBErrorContentContext } from '@joybird/joystagram-components/dist/esm/JBErrorContentContext';
import useCustomEvent from '@joybird/joystagram-components/dist/esm/useCustomEvent';
import routeService from '../../services/routes';

const JBErrorContentProvider = props => {
  const event = useCustomEvent({
    type: ROUTE_ERROR_DATA_UPDATED,
  });
  const [random, setRandom] = useState(0);

  // Triggering a re-render when the component is mounted to get the latest route data.
  useEffect(() => {
    setRandom(Math.random());
  }, []);

  const content = useMemo(() => {
    return {
      error404:
        routeService.react404RouteComponents || 'Error: Page not found.',
      error500:
        routeService.react500RouteComponents || 'Error: Something went wrong!',
    };
  }, [event, random]);

  return (
    <JBErrorContentContext.Provider
      value={{
        content,
      }}
    >
      {props.children}
    </JBErrorContentContext.Provider>
  );
};

export default JBErrorContentProvider;
