const getPageType = (routeInfo = {}) => {
  // first find home page.
  if (routeInfo.route === '/') {
    return 'home';
  } else if (
    [
      ':slug',
      'collections/:slug',
      'furniture/:slug',
      'collections',
      'products',
      'instock',
      'clearance',
      'modular-sofas',
    ].includes(routeInfo.route)
  ) {
    return 'category';
  } else if (
    routeInfo.route.includes(':category/:slug') ||
    routeInfo.route === 'modular/:slug'
  ) {
    return 'product';
  } else if (['checkout'].includes(routeInfo.route)) {
    return 'purchase';
  } else if (routeInfo.route.includes('cart')) {
    return 'cart';
  } else if (routeInfo.route.includes('customer-photos')) {
    return 'customerPhotos';
  }

  return routeInfo.title;
};

export default getPageType;
