export let apolloClient = null;

export function setApolloClient(_apolloClient) {
  apolloClient = _apolloClient;
}

export function isClient() {
  return typeof window !== 'undefined';
}

export function isIE() {
  if (isClient()) {
    if (window.document.documentMode) {
      return true;
    }
  }
  return false;
}

export const hideElement = selector => {
  const element = document.querySelector(selector);

  if (element) {
    element.style.display = 'none';
  }
};

export const showElement = selector => {
  const element = document.querySelector(selector);

  if (element) {
    element.style.display = '';
  }
};

export const displayBlockElement = selector => {
  const element = document.querySelector(selector);

  if (element) {
    element.style.display = 'block';
  }
};
