import avo from '@joybird/joystagram-components/dist/esm/avoAnalytics';
import getRouteInfo from './getRouteInfo';

const getUserCurrentPageType = () => {
  const routeInfo = getRouteInfo();
  console.log(routeInfo);
  if (routeInfo.route === '/') {
    return avo.Location.HOME_PAGE;
  }
  if (
    [
      ':slug',
      'collections/:slug',
      'collections',
      'products',
      'instock',
      'clearance',
      'modular-sofas',
    ].includes(routeInfo.route)
  ) {
    return avo.Location.CATEGORY_PAGE;
  }
  if (
    [':category/:slug', 'modular/:slug', ':category/:slug/:compare?'].includes(
      routeInfo.route
    )
  ) {
    return avo.Location.PDP;
  }

  if (/^checkout/.test(routeInfo.route)) {
    return avo.Location.CHECKOUT;
  }

  if (/^pet-friendly/.test(routeInfo.route)) {
    return avo.Location.PET_FRIENDLY_PAGE;
  }

  if (/^customer\//.test(routeInfo.route)) {
    return avo.Location.CUSTOMER_DASHBOARD;
  }

  if (/^material-swatches/.test(routeInfo.route)) {
    return avo.Location.SHOP_BY_MATERIAL;
  }

  if (/^safeguard-fabrics/.test(routeInfo.route)) {
    return avo.Location.SAFEGUARD_FABRICS_PAGE;
  }
  if (/^lookbooks/.test(routeInfo.route)) {
    return avo.Location.LOOKBOOKS;
  }
  if (/^live/.test(routeInfo.route)) {
    return avo.Location.JOYBIRD_LIVE;
  }

  if (/^blog/.test(routeInfo.route)) {
    return avo.Location.BLOG;
  }
  if (/^videos/.test(routeInfo.route)) {
    return avo.Location.VIDEO_GALLERY;
  }

  if (/^free-design-services/.test(routeInfo.route)) {
    return avo.Location.DESIGN_SERVICES;
  }
  return avo.Location.HOME_PAGE;
};

export default getUserCurrentPageType;
