import React from 'react';
import './IEMsg.css';

const IEMsg = () => {
  return (
    <div className="iemsg-modal">
      Unfortunately our website is not optimized to run on Internet Explorer. In order to have a great browsing experience please upgrade to Microsoft Edge, or use Chrome / Firefox.
    </div>
  )
}

export default IEMsg;