import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Expose the history instance to the global scope, so we can navigate to new
// pages from third party scripts on the /live page

const ExposeHistory = () => {
  const history = useHistory();

  useEffect(() => {
    window._history = history;
  }, [history]);

  return null;
};

export default ExposeHistory;
