// This poll function calls the callback function once the condition function
// returns true.

const poll = ({
  callback = () => {},
  condition = () => true,
  interval = 1000,
  maxRetries = 50,
}) => {
  let retries = 0;

  let pollInterval = setInterval(() => {
    if (retries > maxRetries) {
      clearInterval(pollInterval);
      return;
    }

    const isComplete = condition();

    if (!isComplete) {
      ++retries;
      return;
    }

    clearInterval(pollInterval);

    callback();
  }, interval);
};

export default poll;
