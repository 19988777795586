import avo from '@joybird/joystagram-components/dist/esm/avoAnalytics';
import { getTalkativeCustomerId } from '@joybird/joystagram-components/dist/esm/localStorage';
import getUserCurrentPageType from './getUserCurrentPageType';

const initTalkativeCustomConfig = ({ onTalkativeEnterInteraction }) => {
  window.talkativeCustomConfig = {
    events: {
      exitInteraction() {
        // Exit interaction is triggered when an interaction ends, but before the feedback form is completed.
        const location = getUserCurrentPageType();
        const talkativeCustomerId = getTalkativeCustomerId();
        avo.onSiteChatEnded({
          location,
          talkativeCustomerId,
        });
      },
      enterInteraction() {
        onTalkativeEnterInteraction();
      },
    },
  };
};

export default initTalkativeCustomConfig;
