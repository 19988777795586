import { matchPath } from 'react-router-dom';
import routes from '../../services/routes';

const getRouteInfo = () => {
  const originalPathname = window.location.pathname;
  // remove leading slash, use '/' as default
  const pathname = originalPathname.replace(/^\/+/, '') || '/';

  let routeInfo = { route: '' };

  for (const eachRoute of routes?._routes) {
    const path = matchPath(pathname, {
      path: eachRoute?.route,
      strict: false,
      exact: true,
    });

    if (path) {
      routeInfo = eachRoute;
      break;
    }
  }

  return routeInfo;
};

export default getRouteInfo;
