import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { LOGOUT } from '@joybird/joystagram-components/dist/esm/mutations';

import {
  removeItemInStorage,
  clearUserTokenCookies,
  clearPalomaPixelData,
  clearUserLocationCookie,
  clearVisitorCookies,
} from '@joybird/joystagram-components/dist/esm/localStorage';

import {
  FB_LOGIN_FAVORITES,
  FB_LOGIN_PREV_PATH,
  MODULAR_ORDER_STATE,
  DL_UPCHARGE_PRODUCT_OPTIONS,
} from '@joybird/joystagram-components/dist/esm/Constants';

import useLogoutClient from '@joybird/joystagram-components/dist/esm/useLogoutClient';

const PurgeUserCache = () => {
  const IMPERSONATE_SESSION_COUNTER = 'impersonate-session-counter';
  const POST_ORDER_STORAGE = 'posOrderStorage';
  const ORDER_THANK_YOU = 'orderThankYou';

  const location = useLocation();

  const [logout] = useMutation(LOGOUT);
  const logoutClient = useLogoutClient();

  const reset = new URLSearchParams(location.search).get('reset');
  const resetCache = async () => {
    await logout();
    logoutClient();
    clearUserTokenCookies();
    removeItemInStorage(IMPERSONATE_SESSION_COUNTER);
    removeItemInStorage(POST_ORDER_STORAGE);
    removeItemInStorage(ORDER_THANK_YOU);
    removeItemInStorage(FB_LOGIN_FAVORITES);
    removeItemInStorage(FB_LOGIN_PREV_PATH);
    removeItemInStorage(MODULAR_ORDER_STATE);
    removeItemInStorage(DL_UPCHARGE_PRODUCT_OPTIONS);

    clearPalomaPixelData();
    clearUserLocationCookie();

    clearVisitorCookies();
    window.location.href = '/';
  };

  useEffect(() => {
    if (parseInt(reset, 10) === 1) {
      resetCache();
    }
  }, [reset]);
  return null;
};
export default PurgeUserCache;
