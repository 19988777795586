import loadScript from './loadScript';

const loadUJETScript = () => {
  setTimeout(() => {
    loadScript({
      id: 'ujet-chat-widget-script',
      attributes: {
        src: 'https://websdk.ujet.co/v2/loader.js',
      },
    });
  }, 3000);
};

export default loadUJETScript;
