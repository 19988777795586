import getEnvValue from '@joybird/joystagram-components/dist/esm/getEnvValue';

const isTalkativeChatEnabled = () => {
  const TALKATIVE_ENABLED =
    process.env.NEXT_PUBLIC_TALKATIVE_ENABLED ??
    getEnvValue('REACT_APP_TALKATIVE_ENABLED');

  return TALKATIVE_ENABLED === 'yes';
};

export default isTalkativeChatEnabled;
